import { useCallback, useEffect, useState } from "react";

import { rtbInstance } from "@api/instance";
import { useQuery } from "@tanstack/react-query";

import { handleApiError } from "@features/config";

import { Skill } from "@dto/skill";

type Request = {
  pageSize?: number;
  page?: number;
  searchContent?: string;
};
type Response = {
  success: boolean;
  data?: Skill[];
  totalItems?: number;
  message?: string;
};

export const findSkills = async ({
  pageSize = 50,
  page = 1,
  searchContent = "",
}: Request): Promise<Response> => {
  try {
    const params = { params: { name: searchContent, pageSize, page } };
    const { data } = await rtbInstance.get(`/skills`, params);
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

const useFindSkills = (initParams: Partial<Request>) => {
  const [params, setParams] = useState(initParams);
  const { data, refetch, ...rest } = useQuery({
    queryKey: ["findSkills", params],
    queryFn: () => findSkills(params),
    enabled: !!initParams.searchContent,
  });

  const handleChangeParams = useCallback((params: Partial<Request>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  useEffect(() => {
    if (params.searchContent) {
      refetch();
    }
  }, [params, refetch]);

  return {
    skills: data?.data || [],
    totalItems: data?.totalItems || 0,
    handleChangeParams,
    ...rest,
  };
};

export default useFindSkills;
