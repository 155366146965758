import { getUrls, uploadFile } from "@api/upload";

export const uploadImagesToServer = async (
  files: File[],
  endpoint?: string
): Promise<string[]> => {
  const fileNames = files.map((f) => f.name);
  const { success, data } = await getUrls(fileNames, endpoint);
  if (!success) {
    return [];
  }
  const promises = data?.map(({ uploadLink }: { uploadLink: string }, index: any) =>
    uploadFile(uploadLink, files[index], {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
  );

  try {
    await Promise.all(promises);
    return data?.map((i) => i.imageLink);
  } catch (e) {
    return [];
  }
};
