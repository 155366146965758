import { rtbInstance } from "@api/instance";
import { useMutation, useQuery } from "@tanstack/react-query";

import { handleApiError } from "@features/config";

export type Request = {
  title: string;
  description: string;
  attachment?: string[];
  deliverables?: string;
  skills: { id: string; name: string }[];
  budget: number;
  projectId: string;
  regional: string;
};

type Response =
  | {
      jobId: string;
      projectId: string;
      shortId: string;
    }
  | {
      success: boolean;
      message: string;
    };

export const createJob = async (payload: Request): Promise<Response> => {
  try {
    const response = await rtbInstance.post("/jobs", payload);
    return response?.data;
  } catch (error) {
    return handleApiError(error);
  }
};

const useCreateJob = () => {
  return useMutation({
    mutationFn: createJob,
  });
};

export default useCreateJob;
